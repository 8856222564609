import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from './modules/core/services/api.service';
import { AuthService } from './modules/core/services/auth.service';
import { CommonService } from './modules/core/services/common.service';
import { LoaderService } from './modules/core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public isLoggedIn: boolean = false;
  isProgressRunning: Subject<boolean> = this.loaderService.isProgressBarRunning;
  isLoaderRunning: Subject<boolean> = this.loaderService.isLoaderRunning;
  public isCookiesAccepted: boolean = false;
  public isCookiesNotBlocked: boolean = true;
  public isAcceptedBeforeLogin: boolean = true;

  constructor(private authService: AuthService, private loaderService: LoaderService, private commonService: CommonService, private apiService: ApiService) {
    this.isLoggedIn = authService.isLoggedIn();
    this.isCookiesNotBlocked = navigator.cookieEnabled;
    if (this.isLoggedIn) {
      this.commonService.checkAndLogoutUser();
      this.commonService.setTimerConfigValue();
      this.commonService.setUserIdleWatch();
      this.checkCookiesAccepted();
    }
  }

  public checkCookiesAccepted() {
    this.apiService.get('user/cookies/info').subscribe((res: any) => {
      if (res) {
        this.isCookiesAccepted = res.data.isCookiesAccepted;
        this.commonService.isCookiesAccepted = res.data.isCookiesAccepted;
      }
    })
  }

  ngOnInit(): void {
    this.isCookiesNotBlocked = navigator.cookieEnabled;
  }

  public cookiesBeforeLogin(data: any) {
    this.isAcceptedBeforeLogin = data;
  }
}
