<div class="main_container">
  <div class="body_container1">
    <div class="left_text">
      We value your privacy. This website uses cookies to enable website functionality and help us remember you. You can find more information about the cookies we use in our Privacy Policy.
    </div>
    <div class="right_text">
      <p *ngIf="isLoggedIn" class="action_button" (click)="acceptCookies()">   GOT IT</p>
      <p *ngIf="!isLoggedIn" class="action_button" (click)="acceptCookiesWithoutLoggedIn()">   GOT IT</p>
    </div>
  </div>
</div>

